import { createApp } from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus';
import 'element-plus/dist/index.css'
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import swiper from './swiper'

import appScrollbar from "@/components/app-scrollbar";
import appRichtext from "@/components/app-richtext";
import titleLine from "@/components/title-line.vue";
import moreDetail from "@/components/more-detail.vue";
import request from '@/utils/request';
import utils from '@/utils';
import dayjs from 'dayjs';
// import Es6Promise from 'es6-promise' 
// require('es6-promise').polyfill() 
// Es6Promise.polyfill()



const app = createApp(App)

app
  .use(store)
  .use(router)
  .use(ElementPlus, {
    locale: zhCn,
    size: 'large'
  })
  .use(swiper)
  .component('title-line', titleLine)
  .component('more-detail', moreDetail)
  .component('app-scrollbar', appScrollbar)
  .component('app-richtext', appRichtext)
  .mount('#app');

app.config.globalProperties.$request = request;
app.config.globalProperties.$utils = utils;
app.config.globalProperties.$dayjs = dayjs;
