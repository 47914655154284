import axios from 'axios';
//import store from '@/store'

//  import qs from 'qs'

const result = axios.create({
  baseURL: process.env.VUE_APP_API_URL + 'api',
  // paramsSerializer: function (params) {
  //   return qs.stringify(params, { arrayFormat: 'brackets' })
  // },
})

// 添加请求拦截器
result.interceptors.request.use(function (config) {
  // 返回设置
  return config;
}, function (error) {
  return Promise.reject(error);
});

// response 拦截器
result.interceptors.response.use(function (response) {

  switch (response.data.code) {
    case 0:
      throw new Error(response.data.msg)
    case 1: //1成功
      return response.data;
    case 401:

      break;
    case 403:
      throw new Error(response.data.msg)
    case 404:
      throw new Error(response.data.msg)
    case 429:
      throw new Error(response.data.msg)
    case 500:
      throw new Error(response.data.msg)
    case 503:
      throw new Error(response.data.msg)
    default:

      throw new Error(response.data.msg)
  }
}, function (error) {


  return Promise.reject(error);
});


export default result