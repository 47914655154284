<template>
  <!-- <div class="richtext"> -->
  <app-scrollbar class="richtext-scroll">
    <template v-if="value && value.length">
      <div class="richtext-pre" v-if="pre">{{ value }}</div>
      <div class="richtext-html" v-else v-html="value"></div>
    </template>
  </app-scrollbar>
  <!-- </div> -->
</template>

<script>
export default {
  components: {},
  props: {
    value: String,
    pre: Boolean,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.richtext {
  // display: flex;
  // width: 100%;
  // height: 100%;
  // flex-direction: column;
  // align-items: stretch;

  &-scroll {
    // height: max-content;
    height: 100%;

    // padding-right: 14px;
    @media screen and (min-width: 1200px) {
      padding-right: 10px;
    }

    @media screen and (max-width: 1200px) {
      padding-right: fn.rpx(24) !important;
    }
  }

  p {
    margin: 0 0 9px;
    line-height: 1.57;
  }

  img,
  video {
    max-width: 100%;
  }

  &-pre {
    white-space: pre-wrap;
    // flex: 1;
    // height: 0;
  }

  &-html {
    // width: 100%;
  }
}
</style>