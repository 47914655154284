export default {
  namespaced: true,
  state: () => ({
    nav: [],
    webInfo: {},
  }),
  getters: {
    /**
      * 根据路由名称查询路由信息
      */
    getNavInfo: (state) => (name) => {
      function getInfo(menu, name) {
        let n;
        for (let i = 0; i < menu.length; i++) {
          if (menu[i].rote == name) {
            n = menu[i];
            return n;
          } else {
            if (menu[i].children.length > 0) {
              n = getInfo(menu[i].children, name)
              if (n != undefined && n != '' && n != null) return n;
            }
          }
        }
      }
      return getInfo(state.nav, name);
    },
  },
  mutations: {
    /**
     * 设置菜单
     * @param {*} list 
     */
    setNav(state, list) {
      state.nav = list
    },
    /**
     * 设置网站信息
     */
    setWebinfo(state, webInfo) {
      state.webInfo = webInfo
    },
  },
  actions: {
  },
}
