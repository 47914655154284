// import { App } from 'vue'

// swiper 额外组件配置
import SwiperCore, { Pagination, Navigation, Autoplay, Thumbs, FreeMode, Controller, Virtual } from 'swiper'

// swiper 单独样式 （less / scss）
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

// swiper 必备组件
import { Swiper, SwiperSlide } from 'swiper/vue'

// 使用额外组件
SwiperCore.use([Pagination, Navigation, Autoplay, Thumbs, FreeMode, Controller, Virtual])

// 全局注册 swiper 必备组件
const plugins = [Swiper, SwiperSlide]

const swiper = {
  install(app) {
    plugins.forEach(item => {
      app.component(item.name, item)
    })
  }
}

export default swiper