<template>
  <!-- 标题带下划线 -->
  <div>
    <div class="titleLinePc">
      <div class="title">{{ title }}</div>
      <div class="line"></div>
      <div class="more-box" v-if="more" @click="onMore">
        <div class="more">查看更多</div>
        <img class="ic" src="../assets/image/home/ic_right.png" />
      </div>
    </div>


    <!-- 移动端 -->
    <div class="titleLineMobile">
      <div class="title">{{ title }}</div>
      <div class="line"></div>
      <div class="more-box" v-if="more" @click="onMore">
        <div class="more">查看更多</div>
        <img class="ic" src="../assets/image/home/ic_right.png" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    title: String,
    more: Boolean
  },

  methods: {
    onMore() {
      this.$emit('more');
    }
  },
};
</script>
<style lang="scss" scoped>
@media screen and (min-width: 1200px) {
  .titleLineMobile {
    display: none;
  }

  .titleLinePc {
    position: relative;

    .more-box {
      cursor: pointer;
      position: absolute;
      right: 0;
      bottom: 20px;
      display: flex;
      height: 20px;
      align-items: center;

      .more {
        font-size: 18px;
       
        font-weight: 400;
        color: color.$base;
        line-height: 20px;
      }

      .ic {
        object-fit: fill;
        margin-left: 10px;
        width: 16px;
        height: 16px;
      }

    }

    .title {
      width: fit-content;
      box-sizing: border-box;
      padding-bottom: 10px;
      font-weight: bold;
      font-size: 26px;
     

      color: #333333;
      border-bottom-color: color.$base;
      border-bottom-width: 3px;
      border-bottom-style: solid;
    }

    .line {
      display: flex;
  
      height: 1px;
      background: color.$base;
    }
  }
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 1200px) {
  .titleLinePc {
    display: none;
  }

  .titleLineMobile {
    position: relative;

    .more-box {
      cursor: pointer;
      position: absolute;
      right: 0;
      bottom: fn.rpx(20);
      display: flex;
      height: fn.rpx(20);
      align-items: center;

      .more {
        font-size: fn.rpx(16);
       
        font-weight: 400;
        color: color.$base;
        line-height: fn.rpx(20);
      }

      .ic {
        object-fit: fill;
        margin-left: fn.rpx(10);
        width: fn.rpx(16);
        height: fn.rpx(16);
      }

    }

    .title {
      width: fit-content;
      box-sizing: border-box;
      padding-bottom: fn.rpx(10);
      font-weight: bold;
      font-size: fn.rpx(26);
     

      color: #333333;
      border-bottom-color: color.$base;
      border-bottom-width: fn.rpx(3);
      border-bottom-style: solid;
    }

    .line {
      display: flex;
      // margin-top: fn.rpx(12);
      height: fn.rpx(1);
      background: color.$base;
    }
  }
}
</style>