<template>
  <div>
    <div class="morePc">
      <div class="morePc-text">{{ name }}</div>
      <img class="morePc-ic" src="../assets/image/publice/ic_right.png" />
    </div>
    <!-- 移动端 -->
    <div class="moreMobile">
      <div class="moreMobile-text">{{ name }}</div>
      <img class="moreMobile-ic" src="../assets/image/publice/ic_right.png" />
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    name: {
      type: String,
      default: "查看详情",
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (min-width: 1200px) {
  .moreMobile {
    display: none;
  }

  .morePc {
    height: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;

    &-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 18px;
      font-weight: 400;
      color: color.$base;
    }

    &-ic {
      object-fit: fill;
      margin-left: 8px;
      width: 12px;
      height: 12px;
    }
  }
}
</style>

<style lang="scss" scoped>
@media screen and (max-width: 1200px) {
  .morePc {
    display: none;
  }

  .moreMobile {
    height: fn.rpx(22);
    cursor: pointer;
    display: flex;
    align-items: center;

    &-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: fn.rpx(16);
      font-weight: 400;
      color: color.$base;
    }

    &-ic {
      object-fit: fill;
      margin-left: fn.rpx(8);
      width: fn.rpx(12);
      height: fn.rpx(14);
    }
  }
}
</style>