

const utils = {
  /**根据宽度显示Pc 移动
   * 返回1 pc 2 移动
   */
  getPc() {
    let typePc;

    if (document.body.clientWidth <= 1200) {
      typePc = 2;
    } else {
      typePc = 1;
    }
    return typePc;
  }
  ,

  /**
   * 根据路由name 查菜单Id
   */
  getNavId(name) {

    let menu = JSON.parse(localStorage.getItem('nav'));
    return this.findNav(menu, name);
  },

  findNav(menu, name) {
    let id;
    for (let i = 0; i < menu.length; i++) {
      if (menu[i].rote == name) {
        id = menu[i].id;
        return id;
      } else {
        if (menu[i].children.length > 0) {
          id = this.findNav(menu[i].children, name)
          if (id != undefined) return id;

        }
      }
    }
  },

  /**
  * 根据路由name 查子菜单
  */
  getNavChild(name) {

    let menu = JSON.parse(localStorage.getItem('nav'));
    return this.findNavChild(menu, name);
  },

  findNavChild(menu, name) {
    let children;
    for (let i = 0; i < menu.length; i++) {
      if (menu[i].rote == name) {
        children = menu[i].children;
        return children;
      } else {
        if (menu[i].children.length > 0) {
          children = this.findNavChild(menu[i].children, name)
          if (children != undefined && children != []) return children;

        }
      }
    }
  },

  /**
 * 根据路由 查对应页面名称
 */
  getNavName(name) {

    let menu = JSON.parse(localStorage.getItem('nav'));
    return this.findNavName(menu, name);
  },

  findNavName(menu, name) {
    let n;
    for (let i = 0; i < menu.length; i++) {
      if (menu[i].rote == name) {
        n = menu[i].name;
        return n;
      } else {
        if (menu[i].children.length > 0) {
          n = this.findNavName(menu[i].children, name)
          if (n != undefined && n != '') return n;

        }
      }
    }
  },


   /**
 * 根据路由名称查对应页面类{}
 */
    getNavClass(name) {

      let menu = JSON.parse(localStorage.getItem('nav'));
      return this.findNavClass(menu, name);
    },
  
    findNavClass(menu, name) {
      let n;
      for (let i = 0; i < menu.length; i++) {
        if (menu[i].rote == name) {
          n = menu[i];
          return n;
        } else {
          if (menu[i].children.length > 0) {
            n = this.findNavClass(menu[i].children, name)
            if (n != undefined && n != ''  && n != null) return n;
  
          }
        }
      }
    },
  /**
      * 时间戳转日期2022-01-02
      */
  timestampToTime(time) {
    var date = new Date(time * 1000);
    var Y = date.getFullYear() + "-";
    var M =
      (date.getMonth() + 1 < 10
        ? "0" + (date.getMonth() + 1)
        : date.getMonth() + 1) + "-";
    var D =
      (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + "";
    return Y + M + D;
  },
  /**
   * 获取当时间 2022-07-28 09:33:80
   */
  getCurrentdate() {
    var date = new Date();
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();

    if (month >= 1 && month <= 9) {
      month = '0' + month;
    }
    if (day >= 0 && day <= 9) {
      day = '0' + day;
    }
    if (hours >= 0 && hours <= 9) {
      hours = '0' + hours;
    }
    if (minutes >= 0 && minutes <= 9) {
      minutes = '0' + minutes;
    }
    if (seconds >= 0 && seconds <= 9) {
      seconds = '0' + seconds;
    }
    var currentdate = year + '-' + month + '-' + day + " " + hours + ":" + minutes + ":" + seconds;
    return currentdate;
  }


}
export default utils