<template>
  <overlayScrollbars
    class="app-scroll"
    :options="{
      autoUpdate: true,
      // paddingAbsolute: true,
    }"
  >
    <slot></slot>
  </overlayScrollbars>
  <!-- <el-scrollbar
    class="app-scroll"
    :options="{
      autoUpdate: true,
      // paddingAbsolute: true,
    }"
  >
    <slot></slot>
  </el-scrollbar> -->
</template>

<script>
import "overlayscrollbars/css/OverlayScrollbars.css";
import { OverlayScrollbarsComponent } from "overlayscrollbars-vue";
export default {
  components: {
    overlayScrollbars: OverlayScrollbarsComponent,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.app-scroll {
  height: 100%;
  box-sizing: content-box;

  &::v-deep(.os-scrollbar-handle) {
    background-color: color.$base !important;
  }

  &::v-deep(.os-scrollbar-track) {
    background-color: rgba(0, 0, 0, 0.1);
  }

  @media screen and (max-width: 1200px) {
    &::v-deep(.os-scrollbar-vertical) {
      width: fn.rpx(12) !important;
    }
    &::v-deep(.os-scrollbar-horizontal) {
      height: fn.rpx(12) !important;
    }
  }
}
</style>