import { createRouter, createWebHashHistory } from "vue-router";
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

NProgress.configure({
  easing: 'ease',  // 动画方式    
  speed: 500,  // 递增进度条的速度    
  showSpinner: false, // 是否显示加载ico    
  trickleSpeed: 200, // 自动递增间隔    
  minimum: 0.3 // 初始化时的最小百分比
})

const routes = [
  {
    path: '/',
    component: () => import('@/views/main.vue'),
    meta: {
      title: '主页'
    },
    redirect: '/home',
    children: [
      { path: '/home', component: () => import('@/views/common/home.vue'), name: 'home', meta: { keepAlive: true } },
      { //企业创新
        path: '/innovation', component: () => import('@/views/modules/innovation/index.vue'), name: 'innovation',
        redirect: '/innovationFirst',
        children: [
          //企业创新
          { path: '/innovationFirst', component: () => import('@/views/modules/innovation/innovationFirst.vue'), name: 'innovationFirst', meta: { parent: 'innovation', name: '企业创新' } },
          //  超级品牌
          { path: '/innovationBrand', component: () => import('@/views/modules/innovation/innovationBrand.vue'), name: 'innovationBrand', meta: { parent: 'innovation' } },
          //科研项目管
          { path: '/innovationScience', component: () => import('@/views/modules/innovation/innovationScience.vue'), name: 'innovationScience', meta: { parent: 'innovation' } },
          //海外
          { path: '/innovationOverseas', component: () => import('@/views/modules/innovation/innovationOverseas.vue'), name: 'innovationOverseas', meta: { parent: 'innovation' } },
          {
            path: '/innovationNew', component: () => import('@/views/modules/innovation/innovationNew.vue'), name: 'innovationNew', meta: { parent: 'innovation' },
            redirect: '/innovationDetail',
            children: [{ path: '/innovationDetail', component: () => import('@/views/modules/search/newsDetail.vue'), name: 'innovationDetail', meta: { parent: 'innovation' }, },]
          },
        ]
      },
      {//投资者关系
        path: '/investors', component: () => import('@/views/modules/investors/index.vue'), name: 'investors',
        redirect: '/investorsService',
        children: [
          //服务
          { path: '/investorsService', component: () => import('@/views/modules/investors/investorsService.vue'), name: 'investorsService', meta: { parent: 'investors' } },
          //定期报告
          { path: '/investorsReport', component: () => import('@/views/modules/investors/investorsReport.vue'), name: 'investorsReport', meta: { parent: 'investors' } },
          //最新公告
          { path: '/investorsNotice', component: () => import('@/views/modules/investors/investorsNotice.vue'), name: 'investorsNotice', meta: { parent: 'investors' } },
          { path: '/investorsDetail', component: () => import('@/views/modules/investors/detail.vue'), name: 'investorsDetail', meta: { parent: 'investors' } }
        ]
      },
      {//产品品质
        path: '/productQualityIndex', component: () => import('@/views/modules/productQuality/index.vue'), name: 'productQualityIndex',
        redirect: '/productFrist',
        children: [
          { path: '/productFrist', component: () => import('@/views/modules/productQuality/productFrist.vue'), name: 'productFrist', meta: { parent: 'productQualityIndex', name: '产品品质' } },

          //产品管理
          { path: '/productMannage', component: () => import('@/views/modules/productQuality/productMannage.vue'), name: 'productMannage', meta: { parent: 'productQualityIndex' } },
          //产品品质
          { path: '/productQuality', component: () => import('@/views/modules/productQuality/productQuality.vue'), name: 'productQuality', meta: { parent: 'productQualityIndex' } },
        ]
      },
      {//产品
        path: '/product', component: () => import('@/views/modules/product/index.vue'), name: 'product',
        redirect: '/productCenter',
        children: [
          //  { path: '/temp', component: () => import('@/views/modules/product/temp.vue'), name: 'temp', meta: { parent: 'product' } },

          //产品中心 成人药
          { path: '/productCenter', component: () => import('@/views/modules/product/productCenter.vue'), name: 'productCenter', meta: { parent: 'product' } },
          //儿童药
          { path: '/productCenterChildren', component: () => import('@/views/modules/product/productCenter.vue'), name: 'productCenterChildren', meta: { parent: 'product' } },

          //产品详情
          { path: '/productDetail', component: () => import('@/views/modules/product/productDetail.vue'), name: 'productDetail', meta: { parent: 'product' } },
        ]
      },


      { //关于我们
        path: '/about', component: () => import('@/views/modules/about/index.vue'), name: 'about',
        redirect: '/groupProfileIndex',
        children: [
          //集团简介主页
          { path: '/groupProfileIndex', component: () => import('@/views/modules/about/groupProfileIndex.vue'), name: 'groupProfileIndex', meta: { parent: 'about' } },
          //集团简介
          {
            path: '/groupProfile', component: () => import('@/views/modules/about/groupProfile.vue'), name: 'groupProfile', meta: { parent: 'about' },
            redirect: '/groupProfileIntroduce',
            children: [
              { path: '/groupProfileIntroduce', component: () => import('@/views/modules/about/groupProfileIntroduce.vue'), name: 'groupProfileIntroduce', meta: { parent: 'about' }, },
              { path: '/groupProfileCulture', component: () => import('@/views/modules/about/groupProfileCulture.vue'), name: 'groupProfileCulture', meta: { parent: 'about' }, },
              { path: '/groupProfileTeam', component: () => import('@/views/modules/about/groupProfileTeam.vue'), name: 'groupProfileTeam', meta: { parent: 'about' } },
              { path: '/groupProfileTeamDetail', component: () => import('@/views/modules/about/groupProfileTeamDetail.vue'), name: 'groupProfileTeamDetail', meta: { parent: 'about' } },
            ]
          },
          //集团动态主页
          { path: '/newsIndex', component: () => import('@/views/modules/about/newsIndex.vue'), name: 'newsIndex', meta: { parent: 'about' } },
          {
            path: '/groupDynamic', component: () => import('@/views/modules/about/groupDynamic.vue'), name: 'groupDynamic', meta: { parent: 'about' },
            redirect: '/groupNews',
            children: [
              { path: '/groupNews', component: () => import('@/views/modules/about/groupNews.vue'), name: 'groupNews', meta: { parent: 'about' }, },
              { path: '/mediaReport', component: () => import('@/views/modules/about/mediaReport.vue'), name: 'mediaReport', meta: { parent: 'about' }, },
              { path: '/newsDetail', component: () => import('@/views/modules/search/newsDetail.vue'), name: 'newsDetail', meta: { parent: 'about' }, }

            ]
          },
          //联系我们
          { path: '/contact', component: () => import('@/views/modules/about/contact.vue'), name: 'contact', meta: { parent: 'about' }, },
          //社会责任
          { path: '/socialResponsibilityIndex', component: () => import('@/views/modules/about/socialResponsibilityIndex.vue'), name: 'socialResponsibilityIndex', meta: { parent: 'about' }, },
          {
            path: '/socialResponsibility', component: () => import('@/views/modules/about/socialResponsibility.vue'), name: 'socialResponsibility', meta: { parent: 'about' },
            redirect: '/socialResponsibilityChildSafety',
            children: [
              { path: '/socialResponsibilityChildSafety', component: () => import('@/views/modules/about/socialResponsibilityChildSafety.vue'), name: 'socialResponsibilityChildSafety', meta: { parent: 'about' }, },
              { path: '/socialResponsibilityCharitableDonations', component: () => import('@/views/modules/about/socialResponsibilityCharitableDonations.vue'), name: 'socialResponsibilityCharitableDonations', meta: { parent: 'about' }, },
              { path: '/socialResponsibilityEnvironment', component: () => import('@/views/modules/about/socialResponsibilityEnvironment.vue'), name: 'socialResponsibilityEnvironment', meta: { parent: 'about' }, },
              { path: '/socialResponsibilityDetail', component: () => import('@/views/modules/search/newsDetail.vue'), name: 'socialResponsibilityDetail', meta: { parent: 'about' }, },
            ]
          },
          //人力资源
          { path: '/humanResourcesIndex', component: () => import('@/views/modules/about/humanResourcesIndex.vue'), name: 'humanResourcesIndex', meta: { parent: 'about' }, },
          {
            path: '/humanResources', component: () => import('@/views/modules/about/humanResources.vue'), name: 'humanResources', meta: { parent: 'about' },
            redirect: '/humanResourcesConcept',
            children: [
              { path: '/humanResourcesConcept', component: () => import('@/views/modules/about/humanResourcesConcept.vue'), name: 'humanResourcesConcept', meta: { parent: 'about' }, },
              //人才招聘
              { path: '/humanResourcesRecruitment', component: () => import('@/views/modules/about/humanResourcesRecruitment.vue'), name: 'humanResourcesRecruitment', meta: { parent: 'about' }, },
              //员工风采
              { path: '/humanResourcesStaff', component: () => import('@/views/modules/about/humanResourcesStaff.vue'), name: 'humanResourcesStaff', meta: { parent: 'about' }, },
              { path: '/humanResourcesNewsDetail', component: () => import('@/views/modules/search/newsDetail.vue'), name: 'humanResourcesNewsDetail', meta: { parent: 'about' }, }
            ]
          },
          //全国布局
          { path: '/nationalLayout', component: () => import('@/views/modules/about/nationalLayout.vue'), name: 'nationalLayout', meta: { parent: 'about' }, },
        ]
      },
      { path: '/detail', component: () => import('@/views/modules/search/newsDetail.vue'), name: 'detail', },
      //搜索
      { path: '/search', component: () => import('@/views/modules/search/index.vue'), name: 'search', meta: { name: '搜索页' } },
      //产品搜索
      { path: '/searchProduct', component: () => import('@/views/modules/product/search.vue'), name: 'searchProduct', meta: { name: '搜索页' } },
    ]
  },
  {
    path: '/:catchAll(.*)',
    component: () => import('@/views/common/404.vue'),
    name: '404',
  }
];
const router = createRouter({
  history: createWebHashHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  NProgress.start();

  next();
});
router.afterEach(() => {
  window.scrollTo(0, 0);

  NProgress.done()
})


// router.beforeEach((to, from, next) => {
//   // chrome
//   document.body.scrollTop = 0
//   // firefox
//   document.documentElement.scrollTop = 0
//   // safari
//   window.pageYOffset = 0
//   next()
// })

export default router;

