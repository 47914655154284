<template >
  <router-view />
</template>


<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "App",
  computed: {
    ...mapState("common", ["webInfo"]),
    ...mapGetters("common", ["getNavInfo"]),
  },
  watch: {
    webInfo: {
      immediate: true,
      handler() {
        this.setTitle();
      },
    },
    $route: {
      immediate: true,
      handler() {
        this.setTitle();
      },
    },
  },
  data() {
    return {};
  },
  mounted() {
    document.body.style.setProperty("--el-color-primary", "#EF7800");
    document.body.style.setProperty("--el-color-primary-light-9", "#EF7800");
    document.body.style.setProperty("--el-color-primary-light-3", "#EF7800");
  },
  methods: {
    /**
     * 设置标题
     */
    setTitle() {
      // 设置站点名称
      const webname = this.webInfo?.webname || "";

      // 获取导航信息
      const navInfo = this.getNavInfo(this.$route.name);

      // 获取页面名称
      const pagename = navInfo?.name || this.$route.meta?.name || "";

      // 拼接标题
      document.title = `${webname}${pagename ? `_${pagename}` : pagename}`;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/style/default.scss";
</style>
